<template>
    <dashboard-layout>
        <div class="content-area__body">
            <!-- counter info -->
            <section class="counter-box section-gap">
                <DashboardStatsLoader v-if="isContentLoading"></DashboardStatsLoader>

                <div class="row row-cols-md-4" v-else>
                    <div class="col">
                        <router-link :to="{name: 'candidate.applied-jobs'}" class="counter__card">
                            <div class="counter__card__icon gradient-danger">
                                <i class="eicon e-briefcase"></i>
                            </div>
                            <div class="counter__card__text">
                                <h4>{{ stats.appliedJobs }}</h4>
                                <p>{{ $t("Applied Jobs") }}</p>
                            </div>
                        </router-link>
                    </div>
                    <div class="col">
                        <router-link :to="{name: 'candidate.unfinished-jobs'}" class="counter__card">
                            <div class="counter__card__icon gradient-love">
                                <i class="eicon e-cv"></i>
                            </div>
                            <div class="counter__card__text">
                                <h4>{{ stats.unfinishedJobs }}</h4>
                                <p>{{ $t("Unfinished Jobs") }}</p>
                            </div>
                        </router-link>
                    </div>
                    <div class="col">
                        <router-link :to="{name: 'applicantConversations'}" class="counter__card">
                            <div class="counter__card__icon gradient-warning">
                                <i class="eicon e-chat"></i>
                            </div>
                            <div class="counter__card__text">
                                <h4>{{ stats.newMessages }}</h4>
                                <p>{{ $t("new message") }}</p>
                            </div>
                        </router-link>
                    </div>
                    <div class="col">
                        <router-link :to="{name: 'notification'}" class="counter__card">
                            <div class="counter__card__icon gradient-primary">
                                <i class="eicon e-notification"></i>
                            </div>
                            <div class="counter__card__text">
                                <h4>{{ stats.notifications }}</h4>
                                <p>{{ $t("Notification") }}</p>
                            </div>
                        </router-link>
                    </div>
                </div>
            </section>

            <!-- data table -->
            <section class="data-table section-gap">
                <div class="section-title-wrap">
                    <h2 class="section-title">{{ $t("Unfinished Jobs") }}</h2>
                </div>
                <TableLoader v-if="isTableLoading"></TableLoader>
                <template v-else>
                    <h4 class="empty-message" v-if="isEmpty">{{ $t("no jobs found") }}</h4>
                    <div class="table-wrap" v-else>
                        <div class="table">
                            <div class="table__row table__head">
                                <div class="table-cell">{{ $t("Job Title") }}</div>
                                <div class="table-cell">{{ $t("Company Name") }}</div>
                                <div class="table-cell text-center">{{ $t("Deadline") }}</div>
                                <div class="table-cell text-center">{{ $t("Vacancies") }}</div>
                                <div class="table-cell">{{ $t("Actions") }}</div>
                            </div>
                            <div class="table__row" v-for="job in unfinishedJobs">
                                <div class="table-cell designation" v-text="job.title"></div>
                                <div class="table-cell designation" v-text="job.companyName"></div>
                                <div class="table-cell job__deadline">
                                    <div class="deadline__text">
                                        <p v-text="job.deadline"></p>
                                        <small>({{ job.daysLeft }})</small>
                                    </div>
                                </div>
                                <div class="table-cell job__vacancies-no text-center" v-text="job.vacancy"></div>
                                <div class="table-cell apply-action">
                                    <a target="_blank" :href="job.applyLink" class="social-button semi-button-success"
                                       title="Continue apply"><i class="eicon e-eye-alt"></i></a>
                                    <a href="#" class="social-button semi-button-danger"
                                       @click.prevent="deleteJob(job.id)" title="Remove job"><i
                                            class="eicon e-delete"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

            </section>
            <section class="data-table section-gap">
                <div class="section-title-wrap">
                    <h2 class="section-title">{{ $t("pending assessments") }}</h2>
                </div>
                <TableLoader v-if="isAssessmentLoading"></TableLoader>
                <template v-else>
                    <h4 class="empty-message" v-if="isAssessmentEmpty">{{ $t("no assessment found") }}</h4>
                    <div class="table-wrap" v-else>
                        <div class="table">
                            <div class="table__row table__head">
                                <div class="table-cell" style="width: 40%">{{ $t("Title") }}</div>
                                <div class="table-cell">{{ $t("Company Name") }}</div>
                                <div class="table-cell text-center">{{ $t("Deadline") }}</div>
                                <div class="table-cell text-center">{{ $t("Duration") }}</div>
                                <div class="table-cell">{{ $t("Actions") }}</div>
                            </div>
                            <div class="table__row" v-for="assessment in assessments" v-if="!assessment.timeEnded">
                                <div class="table-cell designation">
                                    <div class="deadline__text">
                                        <p class="text-break" v-text="assessment.title"></p>
                                        <small v-text="assessment.jobTitle"></small>
                                    </div>
                                </div>
                                <div class="table-cell designation" v-text="assessment.companyName"></div>
                                <div class="table-cell job__deadline">
                                    <div class="deadline__text">
                                        <p v-text="assessment.deadline"></p>
                                        <small v-text="assessment.daysLeft"></small>
                                    </div>
                                </div>
                                <div class="table-cell job__vacancies-no text-center"> {{ assessment.duration }}
                                    {{ $t('Minutes') }}
                                </div>
                                <div class="table-cell apply-action">
                                    <a :href="assessment.applyLink" target="_blank"
                                       class="button info-button">{{ $t("Start Assessment") }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <pagination v-if="!isAssessmentEmpty" :pagination="paginationData" @paginate="getAssessments">
                    </pagination>
                </template>

            </section>
        </div>
    </dashboard-layout>
</template>
<script>
import client from "../../app/api/Client";

const DashboardLayout = () =>  import("../../layouts/DashboardLayout");
const DashboardStatsLoader = () =>  import("../../components/_loaders/DashboardStatsLoader");
const TableLoader = () =>  import("../../components/_loaders/_TableLoader");
const Pagination = () =>  import("../../components/pagination/Pagination");

import {EventBus} from "../../extra/event-bus";
import {NOTIFICATION_CLEARED} from "../../constants/events";

export default {
    components: {
        DashboardLayout,
        DashboardStatsLoader,
        TableLoader,
        Pagination
    },
    data() {
        return {
            isContentLoading: false,
            isTableLoading: false,
            isAssessmentLoading: false,
            stats: {
                appliedJobs: 0,
                unfinishedJobs: 0,
                newMessages: 0,
                notifications: 0,
            },
            unfinishedJobs: [],
            assessments: [],
            paginationData: {}
        }
    },
    computed: {
        isEmpty() {
            return this.unfinishedJobs.length === 0;
        },
        isAssessmentEmpty() {
            return this.assessments.length === 0;
        }
    },
    methods: {
        async deleteJob(jobId) {
            try {
                let confirm = await this.$dialog.confirm({
                    title: this.$t(`Confirmation`),
                    body: this.$t(`Are you sure you want to delete this job?`)
                });
                if (confirm) {
                    let {data} = await client().post(`candidate/job/${jobId}/delete`, {
                        _method: 'DELETE'
                    });
                    this.$toast.success(this.$t(data.message));
                    await this.getUnfinishedJobs();
                }
                return false;
            } catch (err) {
                if (err.response) {
                    this.$toast.error(this.$t(err.response.data.message));
                }
            }
        },
        async getStats() {
            this.isContentLoading = true;
            try {
                let {data: {data}} = await client().get('/candidate/stats');
                this.stats.appliedJobs = data.appliedJobs;
                this.stats.unfinishedJobs = data.unfinishedJobs;
                this.stats.newMessages = data.newMessages;
                this.stats.notifications = data.notifications;
            } catch (e) {
            }
            this.isContentLoading = false;
        },
        async getUnfinishedJobs() {
            this.isTableLoading = true;
            try {
                let {data: {data}} = await client().get('/candidate/unfinished-job');
                this.unfinishedJobs = data;

            } catch (e) {
            }
            this.isTableLoading = false;

        },
        async getAssessments(page) {
            if (typeof page === 'undefined') page = 1;
            this.isAssessmentLoading = true;
            try {
                let {data: {data}} = await client().get(`/candidate/assessments?page=${page}`);
                this.assessments = data.data;
                this.paginationData = data;
            } catch (e) {
            }
            this.isAssessmentLoading = false;

        },
    },
    async mounted() {
        await this.getStats();
        await this.getUnfinishedJobs();
        await this.getAssessments();
        EventBus.$on(NOTIFICATION_CLEARED, count => {
            this.stats.notifications = count;
        })
    },
    beforeDestroy() {
        EventBus.$off(NOTIFICATION_CLEARED);
    },
}
</script>
